import uuid from 'uuid/v4'

let inventory = [
  { categories: ['new arrivals'], name: 'New Gray Headphones', price: '1000', image: '../images/products/img1.jpg', description: 'An amazing new offer for our customers. Incredibly designed set of headphones available now.', brand: 'New Style', currentInventory: 4 },
  { categories: ['headphones', 'new'], name: 'New Blue Headphones', price: '1000', image: '../images/products/img2.jpg', description: 'Beautiful design to listen to amazing music.' , brand: 'New Style' , currentInventory: 2 },
  { categories: ['on sale', 'headphones'], name: 'New Malt Headphones', price: '500', image: '../images/products/img3.jpg', description: 'All new selection of designs available for these set of headphones.' , brand: 'New Style', currentInventory: 11},
  { categories: ['new arrivals', 'headphones'], name: 'New Spring Headphones', price: '900', image: '../images/products/img3.jpg', description: 'Light, smooth and unique design for these headphones.' , brand: 'New Style', currentInventory: 14},
  { categories: ['on sale', 'headphones'], name: 'New Summer', price: '1200', image: '../images/products/img4.jpg', description: 'Great sound for outside, indoors and everywhere.', brand: 'New Style' , currentInventory: 7 },
  { categories: ['new arrivals', 'headphones'], name: ' New Atlantis Headphones', price: '800', image: '../images/products/img5.jpg', description: 'Easy to operare and use. Amazing functionality and sound.', brand: 'New Style', currentInventory: 5 },
  { categories: ['on sale', 'headphones'], name: 'New Winter Headphones', price: '650', image: '../images/products/img6.jpg', description: 'New technology for these incredible wireless headphones.' , brand: 'New Style', currentInventory: 10},
  { categories: ['headphones', 'new'], name: 'New Summer Mild Headphones', price: '1230', image: '../images/products/img7.jpg', description: 'New for our selection this season.', brand: 'New Style', currentInventory: 34 },

  { categories: ['headphones'], name: 'New Silver Mild Headphones', price: '800', image: '../images/products/img8.jpg', description: 'An amazing new offer for our customers. Incredibly designed set of headphones available now.', brand: 'New Style', currentInventory: 43 },
  { categories: ['new arrivals', 'headphones'], name: 'New Gold Headphones', price: '900', image: '../images/products/img9.jpg', description: 'Beautiful design to listen to amazing music.', brand: 'New Style' , currentInventory: 2},
  { categories: ['on sale', 'headphones'], name: 'New Matte Headphones', price: '1200', image: '../images/products/img10.jpg', description: 'New for our selection this season.', brand: 'New Style', currentInventory: 44 },

  { categories: ['on sale', 'headphones'], name: 'New Big', price: '300', image: '../images/products/img11.jpg', description: 'New for our selection this season.' , brand: 'New Style', currentInventory: 22 },
  { categories: ['on sale', 'headphones'], name: 'New South', price: '825', image: '../images/products/img12.jpg', description: 'New for our selection this season.' , brand: 'New Style', currentInventory: 19},
  { categories: ['on sale', 'headphones'], name: 'Great Heaphones', price: '720', image: '../images/products/img13.jpg', description: 'An amazing new offer for our customers. Incredibly designed set of headphones available now.' , brand: 'New Style', currentInventory: 33},
  { categories: ['on sale', 'headphones'], name: 'Wireless Headphones', price: '2000', image: '../images/products/img14.jpg', description: 'An amazing new offer for our customers. Incredibly designed set of headphones available now.', brand: 'New Style', currentInventory: 23 },
  { categories: ['on sale', 'headphones'], name: 'New Style Headphones', price: '1100', image: '../images/products/img15.jpg', description: 'New technology for these incredible wireless headphones.' , brand: 'New Style', currentInventory: 23},
  { categories: ['on sale', 'headphones'], name: 'New Orange Headphones', price: '600', image: '../images/products/img16.jpg', description: 'New technology for these incredible wireless headphones.', brand: 'New Style', currentInventory: 25 },

  { categories: ['on sale', 'headphones'], name: 'Tint Headphones', price: '775', image: '../images/products/img17.jpg', description: 'New technology for these incredible wireless headphones.', brand: 'New Style', currentInventory: 54 },
  { categories: ['on sale', 'headphones'], name: 'New Mountain Headphones', price: '1200', image: '../images/products/img18.jpg', description: 'Easy to operare and use. Amazing functionality and sound.', brand: 'New Style', currentInventory: 27 },
  { categories: ['on sale', 'headphones'], name: 'Lounge Headphones', price: '1600', image: '../images/products/img19.jpg', description: 'Easy to operare and use. Amazing functionality and sound.', brand: 'New Style', currentInventory: 22 },
  { categories: ['on sale', 'headphones'], name: 'Mint Headphones', price: '550', image: '../images/products/img1.jpg', description: 'Easy to operare and use. Amazing functionality and sound.', brand: 'New Style', currentInventory: 32 }, 
]

inventory.map(i => {
  i.id = uuid()
  return i
})

export default inventory